export default {
  nameErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.thisWorkplan.Name.required) return "required_field";
    return null;
  },

  eventTypeErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.thisWorkplan.EventType.required) return "required_field";
    return null;
  },

  scheduleTypeErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.thisWorkplan.RawSchedule.Type.required) return "required_field";
    return null;
  },

  scheduleIntervalErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.thisWorkplan.RawSchedule.Interval.required) return "required_field";
    return null;
  },

  scheduleDateErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.thisWorkplan.RawSchedule.Date.required) return "required_field";
    return null;
  },

  scheduleTimeErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.thisWorkplan.RawSchedule.Time.required) return "required_field";
    return null;
  },

  maxAppointmentAssigmentErrors() {
    if (!this.$v.maxAppointmentAssigmentRounds.$anyError) return null;
    else if (!this.$v.maxAppointmentAssigmentRounds.required) return "required_field";
    else if (this.$v.maxAppointmentAssigmentRounds.$invalid) return "invalid_field";
    else return null;
  },
};