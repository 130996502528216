import Dh from "@/helpers/date.helper";

import CreateModal from "../CreateModal";

import WorkplanService from '@/services/workplan.service';
const service = new WorkplanService();

export default {
  name: 'AgentsWorkplan',

  data() {
    return {
      workplans: [],

      workplan: {
        Target: 'agent',
        Description: null,
        Priority: 0,
        EventType: null,
        RawSchedule: {
          Type: 'interval',
          Interval: null,
          IntervalSpecificDays: [],
          Date: null,
          Time: null,
        },
        Data: null
      },

      eventTypes: [
        {value: 'appointment_interval_assign', text: this.$t('appointment_interval_assign')},
        //{value: 'birthday', text: this.$t('birthday')},
      ],
      scheduleType: [
        {value: 'interval', text: this.$t('interval')},
        {value: 'once', text: this.$t('once')},
      ],
      intervals: [
        {value: 'everyMinute', text: this.$t('everyMinute')},
        {value: 'everyHour', text: this.$t('everyHour')},
        {value: 'everyDayAt', text: this.$t('everyDayAt')},
        {value: 'everyWeekDayAt', text: this.$t('everyWeekDayAt')},
        {value: 'onSpecificDaysAt', text: this.$t('onSpecificDaysAt')},
      ],
      weekDays: [
        {name: 'sunday', selected: false},
        {name: 'monday', selected: true},
        {name: 'tuesday', selected: true},
        {name: 'wednesday', selected: true},
        {name: 'thursday', selected: true},
        {name: 'friday', selected: true},
        {name: 'saturday', selected: false}
      ],

      createModal: {
        show: false,
        workplan: null
      },
      deleteModal: false,
      enableModal: false,
      disableModal: false,
      editModal: false,
      isLoading: false,

      perPage: 25,
      totalRows: 0,
      currentPage: 1,

      // Additional Data
      maxAppointmentAssigmentRounds: 1,
      birthdayEmailTemplate: null,
    }
  },

  components: {
    CreateModal
  },

  computed: {
    loadIndicator() {
      return {type: 'list', bg: '#F3F4FB'}
    },
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        type: 'agent'
      }

      const res = await service.list(this.perPage, skip, filters);
      if (res && !res.error) {
        this.workplans = res.data.workplans;
        this.totalRows = res.data.count;
      }
    },

    async remove() {
      const id = this.deleteModal;

      this.isLoading = true;

      const res = await service.remove(id);
      if (res && !res.error) {
        this.closeModal();

        this.$notify({type: 'success', message: 'Workplan successfully deleted'});

        const index = this.workplans.findIndex(workplan => workplan._id === id);
        if (index > -1) {
          this.workplans.splice(index, 1);
        }
      }
    },

    async enable() {
      const id = this.enableModal;

      this.isLoading = true;

      const res = await service.enable(id);
      if (res && !res.error) {
        this.closeModal();

        this.$notify({type: 'success', message: 'Workplan successfully enabled'});

        const index = this.workplans.findIndex(workplan => workplan._id === id);
        this.workplans[index].Disabled = false;
      }
    },

    async disable() {
      const id = this.disableModal;

      this.isLoading = true;

      const res = await service.disable(id);
      if (res && !res.error) {
        this.closeModal();

        this.$notify({type: 'success', message: 'Workplan successfully disabled'});

        const index = this.workplans.findIndex(workplan => workplan._id === id);
        this.workplans[index].Disabled = true;
      }
    },

    async showCreateModal(workplan) {
      this.createModal.show = true;
      this.createModal.workplan = workplan || null;
    },

    closeModal() {
      this.createModal.show = false;
      this.createModal.workplan = null;
      this.isLoading = false;
      this.deleteModal = false;
      this.enableModal = false;
      this.disableModal = false;
    },

    onUpdated(workplan) {
      this.closeModal();

      const index = this.workplans.findIndex(wp => wp._id === workplan._id);
      if (index > -1) {
        this.workplans.splice(index, 1, workplan);
      }
    },

    selectWeekDay(day) {
      const selected = this.weekDays.filter(el => el.selected);

      if (selected.length > 1) {
        day.selected = !day.selected;
      } else {
        day.selected = true;
      }
    },

    async setPerPage(num) {
      this.perPage = +(num);
      this.$hideModals();
      await this.list();
    },

    async next() {
      await this.list(this.currentPage + 1);
    },

    async prev() {
      await this.list(this.currentPage - 1);
    }
  },

  watch: {
    'workplan.RawSchedule.Interval'(interval) {
      this.workplan.RawSchedule.Date = null;
      if (['everyMinute', 'everyHour'].includes(interval)) {
        this.workplan.RawSchedule.Time = null;
      }
    }
  },

  filters: {
    createdAt(value) {
      if (value) {
        return Dh.customFormat(value, "MMM, DD h:mm a");
      }
      return 'N/A';
    },
  }
}