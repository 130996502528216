import {required, requiredIf, minValue, maxValue, numeric} from 'vuelidate/lib/validators';

export default {
  thisWorkplan: {
    EventType: {
      required
    },
    RawSchedule: {
      Type: {
        required
      },
      Interval: {
        required: requiredIf(schedule => schedule.Type === 'interval'),
      },
      Date: {
        required: requiredIf(schedule => schedule.Type === 'once'),
      },
      Time: {
        required: requiredIf(schedule => {
          if (schedule.Type === 'once') return true;
          else if (!schedule.Interval) return false;
          else return !['everyMinute', 'everyHour'].includes(schedule.Interval);
        }),
      },
    },
  },

  maxAppointmentAssigmentRounds: {
    required: requiredIf(form => form.thisWorkplan.EventType === 'appointment_assign'),
    numeric,
    maxValue: maxValue(10),
    minValue: minValue(1)
  },
}