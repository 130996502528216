import Validations from './validation';
import Errors from './Errors';

import WorkplanService from '@/services/workplan.service';
import AgentService from '@/services/agent.service';

const service = new WorkplanService();
const agentService = new AgentService();

export default {
  name: 'AgentWorkplanCreate',

  props: {
    workplan: null,
    onClose: Function,
    onCreated: Function,
    onUpdated: Function,
  },

  data() {
    return {
      tab: 'config',
      isLoading: false,

      thisWorkplan: {
        Target: 'agent',
        Description: null,
        Priority: 0,
        EventType: null,
        RawSchedule: {
          Type: 'interval',
          Interval: null,
          IntervalSpecificDays: [],
          Date: null,
          Time: null,
        },
        Data: null
      },

      // Additional Data
      maxAppointmentAssigmentRounds: 1,
      birthdayEmailTemplate: null,
      assignToSellers: true,

      eventTypes: [
        {value: 'appointment_interval_assign', text: this.$t('appointment_interval_assign')},
        //{value: 'birthday', text: this.$t('birthday')},
      ],
      scheduleType: [
        {value: 'interval', text: this.$t('interval')},
        {value: 'once', text: this.$t('once')},
      ],
      intervals: [
        {value: 'everyMinute', text: this.$t('everyMinute')},
        {value: 'everyHour', text: this.$t('everyHour')},
        {value: 'everyDayAt', text: this.$t('everyDayAt')},
        {value: 'everyWeekDayAt', text: this.$t('everyWeekDayAt')},
        {value: 'onSpecificDaysAt', text: this.$t('onSpecificDaysAt')},
      ],
      weekDays: [
        {name: 'sunday', selected: false},
        {name: 'monday', selected: true},
        {name: 'tuesday', selected: true},
        {name: 'wednesday', selected: true},
        {name: 'thursday', selected: true},
        {name: 'friday', selected: true},
        {name: 'saturday', selected: false}
      ],

      // Mediators
      agents: [],
      mediators: {
        policy: 'PARALLEL',
        users: [],
      },
      searchAvailable: '',
      searchMediators: '',
      agentSelected: null,
      agentMediatorSelected: null,
    }
  },

  validations: Validations,

  computed: {
    ...Errors,

    editMode() {
      return this.workplan !== null;
    },

    createMode() {
      return this.workplan === null;
    },

    invalidConfig() {
      return false;
    },

    invalidMediator() {
      return false;
    },

    disableTimeBtn() {
      if (this.thisWorkplan.RawSchedule.Type === 'once') return false;
      else if (!this.thisWorkplan.RawSchedule.Interval) return true;
      else return ['everyMinute', 'everyHour'].includes(this.thisWorkplan.RawSchedule.Interval);
    },

    agentss() {
      if (this.searchAvailable.length) {
        return this.agents.filter(agent => {
          return agent.FirstName.toLowerCase().includes(this.searchAvailable.toLowerCase()) ||
              agent.LastName.toLowerCase().includes(this.searchAvailable.toLowerCase());
        });
      }

      return this.agents;
    },

    mediatorss() {
      if (this.searchMediators.length) {
        return this.mediators.users.filter(agent => {
          return agent.FirstName.toLowerCase().includes(this.searchMediators.toLowerCase()) ||
              agent.LastName.toLowerCase().includes(this.searchMediators.toLowerCase());
        });
      }

      return this.mediators.users;
    },
  },

  mounted() {
    this.listAgents();

    if (this.workplan) {
      this.thisWorkplan = this.parseWorkplan();
    }
  },

  methods: {
    parseWorkplan() {
      const _workplan = {...this.workplan};

      this.weekDays = this.weekDays.map(dayObj => {
        dayObj.selected = false;

        _workplan.RawSchedule.IntervalSpecificDays.forEach(day => {
          if (dayObj.name === day) dayObj.selected = true
        })

        return dayObj;
      });

      // Data
      const eventType = _workplan.EventType;
      switch (eventType) {
        case 'appointment_interval_assign':

          this.maxAppointmentAssigmentRounds = _workplan.Data.maxAppointmentAssigmentRounds;
          this.assignToSellers = _workplan.Data.assignToSellers;
          if (_workplan.Data.mediators) this.mediators = _workplan.Data.mediators;
          break;

        default:
      }

      return _workplan;
    },

    listAgents() {
      agentService.list(100, '').then(res => {
        if (res && !res.error) {

          this.agents = res.data.agents.map(el => ({
            _id: el.User._id,
            FirstName: el.User.FirstName,
            LastName: el.User.LastName,
            PolicyPriority: new Date(),
          }));

        }
      });
    },

    async create() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      this.setAdditionalData();

      const res = await service.create(this.thisWorkplan);
      if (res && !res.error) {
        this.closeModal();
        this.$notify({type: 'success', message: 'Workplan successfully created'});
      } else {
        this.$notify({type: 'error', message: 'Couldn\'t not create Workplan'});
      }
    },

    async update() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      const id = this.thisWorkplan._id;

      this.setAdditionalData();

      const res = await service.edit(id, this.thisWorkplan);
      if (res && !res.error) {
        this.$notify({type: 'success', message: 'Workplan successfully edited'});
        this.onUpdated( this.thisWorkplan);
      }
    },

    closeModal() {
      if (this.onClose) this.onClose();
    },

    addMediator(user) {
      const mediatorIdx = this.mediators.users.findIndex(a => a._id === user._id);
      if (mediatorIdx > -1) {
        return;
      }

      const agentIdx = this.agents.findIndex(a => a._id === user._id);
      if (agentIdx >= 0) {
        this.mediators.users.push(this.agents[agentIdx]);
        this.agents.splice(agentIdx, 1);
      }
    },

    removeMediator(user) {
      const index = this.mediators.users.findIndex(a => a._id === user._id);
      if (index > -1) {
        this.agents.push(this.mediators.users[index]);
        this.mediators.users.splice(index, 1);
      }
    },

    selectWeekDay(day) {
      const selected = this.weekDays.filter(el => el.selected);

      if (selected.length > 1) {
        day.selected = !day.selected;
      } else {
        day.selected = true;
      }
    },

    setAdditionalData() {
      // Specific Days
      this.thisWorkplan.RawSchedule.IntervalSpecificDays = [];
      if (this.thisWorkplan.RawSchedule.Interval === 'onSpecificDaysAt') {
        this.weekDays.forEach(el => {
          if (el.selected) {
            this.thisWorkplan.RawSchedule.IntervalSpecificDays.push(el.name)
          }
        });
      }

      // Data
      const eventType = this.thisWorkplan.EventType;
      switch (eventType) {
        case 'appointment_interval_assign':

          this.thisWorkplan.Data = {
            maxAppointmentAssigmentRounds: this.maxAppointmentAssigmentRounds,
          }

          if (this.mediators.users.length) {
            this.thisWorkplan.Data.mediators = this.mediators;
          }

          this.thisWorkplan.Data.assignToSellers = this.assignToSellers;

          break;

        default:
          this.thisWorkplan.Data = null
      }
    },
  },
}