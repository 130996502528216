import IService from './iservice';
import config from "@/config";
import store from "../store/store";

const program_server = config[config.stage].program_server;

class WorkplanService extends IService {
    constructor(http) {
        super(http);
        this.store = store;
    }

    async create(data) {
        try {
            const response  = await this.http.post(`${program_server}/api/workplan`, data);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async list(limit, skip, filters) {
        try {
            this.addLoading('list');
            const response = await this.http.get(`${program_server}/api/workplan?limit=${limit}&skip=${skip}`, filters);
            this.delLoading('list');
            return response.data;
        } catch (e) {
            this.delLoading('list');
            return null;
        }
    }

    async remove(id) {
        try {
            const response  = await this.http.delete(`${program_server}/api/workplan/${id}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async enable(id) {
        try {
            const response  = await this.http.put(`${program_server}/api/workplan/${id}/set-status`, {disabled: false});
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async disable(id) {
        try {
            const response  = await this.http.put(`${program_server}/api/workplan/${id}/set-status`, {disabled: true});
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async get(id) {
        try {
            const response  = await this.http.get(`${program_server}/api/workplan/${id}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async edit(id, record) {
        try {
            const response  = await this.http.put(`${program_server}/api/workplan/${id}`, record);
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default WorkplanService;